/*!
 * Core Theme JS-functionality
 *
 * @version 1.0.0
 * @since 1.0.0
 */

// JavaScript code should be executed in "strict mode".
'use strict';

/* global jQuery */

// jquery no-conflict
jQuery.noConflict();

// on DOM ready
( function( $ ) {

	/**
	 * File skip-link-focus-fix.js.
	 *
	 * Helps with accessibility for keyboard only users.
	 * @link https://git.io/vWdr2
	 *
	 * @since	1.0.0
	 */
	var isIe = /(trident|msie)/i.test( navigator.userAgent );

	if ( isIe && document.getElementById && window.addEventListener ) {
		window.addEventListener( 'hashchange', function() {
			var id = location.hash.substring( 1 ),
				element;

			if ( ! ( /^[A-z0-9_-]+$/.test( id ) ) ) {
				return;
			}

			element = document.getElementById( id );

			if ( element ) {
				if ( ! ( /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) ) {
					element.tabIndex = -1;
				}

				element.focus();
			}
		}, false );
	}

	/*
	 * Accessibility tabbing on primary navigation menu
	 *
	 * hoverIntent v1.9.0 // 2014.06.29 // jQuery v1.9.1+
	 * @linkhttp://cherne.net/brian/resources/jquery.hoverIntent.html
	 *
	 * @since 1.0.0
	 */
	function tabHelper(){
		$('#site-navigation li').hoverIntent(function() {
			if($(this).find('ul.sub-menu').length>0) {
				$(this).find('ul.sub-menu').first().slideDown(200);
			}
		}, function() {
			if($(this).find('ul.sub-menu').length>0) {
				$(this).find('ul.sub-menu').slideUp(150);
			}
		});

		$('#site-navigation li:has("ul.sub-menu")').on('keyup', function(e) {
			if(e.keyCode === 9) {
				$(this).find('ul.sub-menu').first().slideDown(200);
			}
		});

		$('#site-navigation li > ul > li:last-child > a').on('keydown', function(e) {
			if ( e.keyCode === 9 && $(this).parent('li').children('ul').length === 0 ) {
				$(this).parent('li').parent('ul').slideUp(150);
			}
		});
	}

	/*
	 * mMenu plugin
	 * @link http://mmenu.frebsite.nl/
	 * v7.0.3
	 *
	 * @since 1.0.0
	 */
	function mMenu(){

		// settings
		$('#mobile-navigation').mmenu({
			extensions: [
				'border-full',
				'pagedim-black',
				'theme-dark',
				'position-back',
				'position-right',
				'fx-panels-none'
			],
			wrappers: [
				'wordpress',
				'bootstrap4'
			],
			slidingSubmenus: false,
			navbar : {
				add: false
			}
		});

	}

	/*
	 * Run these on DOM ready
	 *
	 * @since 1.0.0
	 */
	function init(){

		// if hoverintent library is active and function exist
		if ( jQuery().hoverIntent ) {
			tabHelper();
		}

		// if mmenu library is active and function exist
		if( jQuery().mmenu ){
			mMenu();
		}

		// prevent default behaviour on toggle menu button
		$('#menu-toggle').click(function(e){
			e.preventDefault();
		});

		$('#menu-close').click(function(e){
			e.preventDefault();
		});

		// Instantiate FancyBox Options, if the library exist
		// http://fancyapps.com/fancybox/3/docs/#options
		if( jQuery().fancybox ){
			$('[data-fancybox]').fancybox({

				caption : function() {
					return $(this).find('figcaption').html();
				}
			});
		}

	}

	/*
	 * Run all functions from init
	 *
	 * @since 1.0.0
	 */
	init();

} )( jQuery );